export default {
  'header.guest': 'Invitado',
  'header.welcome': 'Hola, amigo',
  'header.modifyPassword': 'Modificar Contraseña',
  'header.logout': 'Cerrar Sesión',
  'header.read': 'Leer Datos',
  'header.timing': 'Temporización del Dispositivo',
  'header.report': 'Informe',
  'header.history': 'Datos Históricos',
  'header.chart': 'Gráfico Estadístico',
  'header.fullScreen': 'Alternar pantalla completa',
  'header.theme': 'Alternar tema',
  'header.theme.light': 'Claro',
  'header.theme.dark': 'Oscuro',
  'header.component.download': 'Controlador',
  'header.password.original': 'Contraseña Original',
  'header.password.original.placeholder': 'Por favor, ingrese la contraseña original',
  'header.password.new': 'Nueva Contraseña',
  'header.password.new.placeholder': 'Por favor, ingrese la nueva contraseña',
  'header.password.confirm': 'Confirmar Contraseña',
  'header.password.confirm.placeholder': 'Por favor, ingrese la confirmación de contraseña',
  'header.password.notMatch': 'Las dos entradas de contraseña son diferentes, por favor, confirme',
  'header.password.same':
    'La contraseña original es la misma que la nueva contraseña, por lo que no es necesario cambiar la contraseña',
  'header.password.blank': 'La contraseña no puede estar en blanco',
  'header.password.modify.success':
    'Contraseña actualizada, redirigiendo al inicio de sesión en 5 segundos',
  'header.device.connected': 'Dispositivo Conectado',
  'header.device.disconnected': 'Dispositivo Desconectado',
  'header.modifyEmail': 'Modificar correo electrónico',
};
