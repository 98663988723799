export default {
  'event.title': 'Configuración de Eventos',
  'event.btn.add': 'Agregar Evento',
  'event.btn.import': 'Importar Evento',
  'event.btn.export': 'Exportar Evento',
  'event.table.code': 'ID del Evento',
  'event.table.name': 'Nombre del Evento',
  'event.table.simple': 'Evento Simple',
  'event.table.common': 'Evento Común',
  'event.input.code.placeholder': 'Por favor, ingrese el ID del evento',
  'event.input.name.placeholder': 'Por favor, ingrese el nombre del evento',
  'event.table.moveup': 'Mover hacia arriba',
  'event.table.movedown': 'Mover hacia abajo',
};
