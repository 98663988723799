export default {
  'exception.fail': 'Solicitud Fallida',
  'exception.unknow': 'Ocurrió un error desconocido',
  'exception.network': 'Error de Red',
  'exception.network.desc': 'No se puede conectar al servidor',
  'exception.403': 'Lo sentimos, no está autorizado para acceder a este recurso.',
  'exception.404': 'Vaya..., no se puede encontrar el recurso.',
  'exception.500': 'Orz, el servidor puede haber encontrado algunos problemas.',
  'exception.400': 'Solicitud Incorrecta',
  'exception.401': 'Credenciales de Usuario Inválidas',
  'exception.502': '502',
  'exception.503': '503',
  'exception.504': 'Tiempo de espera de la puerta de enlace',
  'exception.crash': 'Vaya, parece que hemos encontrado algo inesperado...',
  'exception.disconnect': 'No se pueden cargar recursos del servidor...',
};
