export default {
  'module.1': 'Configuración de Patrullaje',
  'module.101': 'Configuración de Empresa',
  'module.10101': 'Agregar Empresa',
  'module.10102': 'Editar Empresa',
  'module.10103': 'Eliminar Empresa',
  'module.10104': 'Ver Empresa',
  'module.102': 'Configuración de Eventos',
  'module.10201': 'Agregar Evento',
  'module.10202': 'Editar Evento',
  'module.10203': 'Eliminar Evento',
  'module.10204': 'Ver Menú',
  'module.10205': 'Importar Evento',
  'module.10206': 'Exportar Evento',
  'module.10207': 'Lectura por Lotes',
  'module.103': 'Configuración de Guardia',
  'module.10301': 'Agregar Guardia',
  'module.10302': 'Editar Guardia',
  'module.10303': 'Eliminar Guardia',
  'module.10304': 'Ver Menú',
  'module.10305': 'Importar Guardia',
  'module.10306': 'Exportar Guardia',
  'module.10307': 'Lectura por Lotes',
  'module.10308': 'Recoger Huella Dactilar',
  'module.10309': 'Editar Huella Dactilar',
  'module.10310': 'Descargar Huella Dactilar',
  'module.104': 'Configuración de Puntos de Control',
  'module.10401': 'Agregar Punto de Control',
  'module.10402': 'Editar',
  'module.10403': 'Eliminar Punto de Control',
  'module.10404': 'Ver Menú',
  'module.10405': 'Importar Punto de Control',
  'module.10406': 'Descargar Datos',
  'module.10407': 'Lectura por Lotes',
  'module.10408': 'Asignar Contenido',
  'module.10409': 'Exportar Punto de Control',
  'module.105': 'Configuración de Plan',
  'module.10501': 'Agregar Plan',
  'module.10502': 'Editar Plan',
  'module.10503': 'Eliminar Plan',
  'module.10504': 'Ver Menú',
  'module.10505': 'Asignar Dispositivo',
  'module.10506': 'Exportar Plan',
  'module.106': 'Registro de Dispositivo de Inspección',
  'module.10601': 'Registro de Dispositivo',
  'module.10602': 'Eliminar Dispositivo',
  'module.10604': 'Ver Menú',
  'module.107': 'Configuración de Contactos',
  'module.10701': 'Agregar Contactos',
  'module.10702': 'Modificar',
  'module.10703': 'Eliminar',
  'module.10704': 'Ver Menú',
  'module.10706': 'Importar Contacto',
  'module.10705': 'Exportar Contacto',
  'module.108': 'Información de Recibo',
  'module.10801': 'Agregar Recibo',
  'module.10802': 'Editar Información de Recibo',
  'module.10803': 'Eliminar Información de Recibo',
  'module.10804': 'Ver Menú',
  'module.10805': 'Importar Recibo',
  'module.10806': 'Exportar Recibo',
  'module.109': 'Descargar Datos',
  'module.10901': 'Descargar Datos',
  'module.10904': 'Ver Menú',
  'module.110': 'Configuración de Contenido',
  'module.11001': 'Agregar Contenido',
  'module.11002': 'Editar Contenido',
  'module.11003': 'Eliminar Contenido',
  'module.11004': 'Ver Menú',
  'module.11005': 'Seleccionar Punto de Control',
  'module.111': 'Configuración de Walkie-talkie',
  'module.11101': 'Agregar Grupo',
  'module.11102': 'Editar Grupo',
  'module.11103': 'Eliminar Grupo',
  'module.11104': 'Ver Menú',
  'module.112': 'Configuración de Voz',
  'module.11204': 'Ver Menú',
  'module.113': 'Descargar Datos',
  'module.11301': 'Descargar Datos',
  'module.11304': 'Ver Menú',
  'module.114': 'Valla Electrónica',
  'module.11401': 'Nueva Valla',
  'module.11402': 'Editar Valla',
  'module.11403': 'Eliminar Valla',
  'module.11404': 'Ver Menú',
  'module.2': 'Operación Básica',
  'module.201': 'Leer Datos',
  'module.20104': 'Leer Datos',
  'module.202': 'Datos Históricos',
  'module.20201': 'Exportar Datos Históricos',
  'module.20203': 'Reanálisis',
  'module.20204': 'Ver Menú',
  'module.203': 'Informe de Patrullaje',
  'module.20301': 'Exportar Informe de Inspección',
  'module.20304': 'Ver Menú',
  'module.204': 'Gráfico Estadístico',
  'module.20401': 'Derivar Estadísticas',
  'module.20404': 'Ver Menú',
  'module.205': 'Datos de Omisión',
  'module.20501': 'Exportar Datos de Omisión',
  'module.20504': 'Ver Menú',
  'module.206': 'Datos de Alarma',
  'module.20601': 'Exportar Datos de Alarma',
  'module.20603': 'Eliminar Datos de Alarma',
  'module.20604': 'Ver Menú',
  'module.207': 'Información de Registro',
  'module.20701': 'Exportar Datos de Registro',
  'module.20704': 'Ver Menú',
  'module.208': 'Limpiar Datos del Dispositivo',
  'module.20804': 'Limpiar Datos del Dispositivo',
  'module.209': 'Temporización del Dispositivo',
  'module.20904': 'Temporización del Dispositivo',
  'module.210': 'Eliminar Huellas Dactilares',
  'module.21004': 'Eliminar Huellas Dactilares',
  'module.211': 'Monitoreo',
  'module.21101': 'Editar Punto',
  'module.21102': 'Reproducción de Ruta',
  'module.21104': 'Ver Menú',
  'module.21105': 'Ver Datos en Vivo',
  'module.212': 'Datos Originales',
  'module.21201': 'Exportar Datos en Bruto',
  'module.21202': 'Conversión de Etiqueta Desconocida',
  'module.21204': 'Ver Menú',
  'module.213': 'Calendario de Patrullaje',
  'module.21304': 'Ver Menú',
  'module.214': 'Datos de Asistencia',
  'module.21401': 'Exportar Datos de Asistencia',
  'module.21404': 'Ver Menú',
  'module.215': 'Detalle del Informe',
  'module.21501': 'Exportar Detalles de Inspección',
  'module.21504': 'Ver Menú',
  'module.3': 'Mantenimiento de Datos',
  'module.302': 'Lista de Dispositivos',
  'module.30201': 'Activar Dispositivo',
  'module.30202': 'Agregar Dispositivo',
  'module.30203': 'Eliminar Dispositivo',
  'module.30204': 'Ver Menú',
  'module.30205': 'Reemplazar Dispositivo',
  'module.30206': 'Configuración de Parámetros',
  'module.30207': 'Editar Observación',
  'module.303': 'Configuración del Sistema',
  'module.30304': 'Configuración del Sistema',
  'module.304': 'Configuración del Dispositivo',
  'module.30404': 'Ver Configuración de Parámetros del Dispositivo',
  'module.30408': 'Configuración del Reloj',
  'module.305': 'Configuración de Comunicación',
  'module.30504': 'Ver Configuración de Parámetros de Comunicación',
  'module.306': 'Configuración de Permisos',
  'module.30601': 'Agregar Rol',
  'module.30602': 'Editar Rol',
  'module.30603': 'Eliminar Rol',
  'module.30604': 'Ver Menú',
  'module.307': 'Configuración de Operador',
  'module.30701': 'Agregar Operador',
  'module.30702': 'Editar Operador',
  'module.30703': 'Eliminar Operador',
  'module.30704': 'Ver Menú',
  'module.30705': 'Ver Empresa',
  'module.30706': 'Asignar Permiso',
  'module.30708': 'Modificar Contraseña',
  'module.308': 'Inicialización del Dispositivo',
  'module.30804': 'Inicialización del Dispositivo',
  'module.5': 'Tablero',
  'module.216': 'BI',
  'module.21604': 'Ver Menú',
};
