export default {
  'request.10000': 'Empresa no válida',
  'request.10001': 'Nombre de usuario o contraseña no válidos',
  'request.10002': 'Licencia de prueba expirada',
  'request.10003': 'Código de verificación incorrecto',
  'request.10004': 'El nombre de la empresa ya existe, por favor ingrese otro',
  'request.10005': 'El número de teléfono móvil ya está registrado, no repita la operación',
  'request.10006': 'Misma Contraseña',
  'request.10007': 'Usuario no existe',
  'request.10008': 'Contraseña original incorrecta',
  'request.10009': 'Ya ha iniciado sesión. No puede iniciar sesión nuevamente. Inténtelo más tarde',
  'request.10010':
    'El número de usuarios conectados ha superado el máximo permitido por el sistema',
  'request.10011': 'El correo electrónico ya está registrado, no repita la operación',
  'request.10012': 'El número de equipo ya está registrado, por favor verifique',
  'request.10013': 'Por favor, inténtelo de nuevo en 1 minuto',
  'request.10014':
    'La activación del sistema falló. Haga clic derecho en el código de verificación para obtener el código de activación del sistema',
  'request.10015':
    'El usuario actual no tiene permisos asignados. Contacte al administrador para configurar los permisos antes de iniciar sesión en el sistema',
  'request.10016': 'Por favor, inserte el dongle',
};
