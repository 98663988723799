export default {
  'company.table.index': 'Núm.',
  'company.table.buy.count': 'Cantidad de Compra',
  'company.table.remain.count': 'Cantidad Restante',
  'company.table.term': 'Tiempo de Uso',
  'company.table.buy.time': 'Hora de Compra',
  'company.table.status': 'Estado',
  'company.table.device.type': 'Tipo de Dispositivo',
  'company.table.operate': 'Operar',
  'company.table.name': 'Nombre de la Empresa',
  'company.table.code': 'Código de la Empresa',
  'company.table.software.version': 'Versión del Software',
  'company.table.begin.date': 'Hora de Inicio',
  'company.table.end.date': 'Hora de Finalización',
  'company.table.sales.man': 'Vendedor',
  'company.table.device.model': 'Modelo del Dispositivo',
  'company.add.phone.app.login': 'Iniciar sesión en la aplicación móvil',
  'company.table.device.count': 'Número de Dispositivos',
  'company.table.expire.query': 'Consulta de Expiración',
  'company.table.date.filer': 'Filtrado por Fecha',
  'company.info.list': 'Lista de Empresas',
  'company.save': 'Guardar',
  'company.yes': 'Sí',
  'company.no': 'No',
  'company.edit': 'Editar',
  'company.version.all': 'Todas las versiones',
  'company.version.formal': 'Edición oficial',
  'company.version.try': 'Versión de prueba',
  'company.version.demo': 'Edición de demostración',
  'company.version.network': 'Versión de red',
  'company.version.free': 'Versión gratuita',
  'company.all': 'Todas las empresas',
  'company.soon.expire': 'Próximo a expirar',
  'company.already.expire': 'Ha expirado',
  'company.adding': 'Agregando...',
  'company.add.fail': 'Error al agregar empresa',
  'company.changing': 'Editando...',
  'company.change.fail': 'Error al modificar empresa',
  'company.btn.add': 'Agregar Empresa',
  'company.btn.card': 'Información de la Tarjeta de Recarga',
  'comany.btn.delete': 'Eliminar Empresa',
  'company.delete.tips': '¿Seguro que desea eliminar el registro?',
  'company.reset.password': 'Restablecer Contraseña',
  'company.reset.password.select':
    'Por favor seleccione el registro para restablecer la contraseña primero',
  'company.reset.password.success': 'Restablecimiento de contraseña exitoso',
  'company.renewal.customer': 'Información del Cliente de Renovación',
  'company.register.device.all': 'Lista de Dispositivos',
  'company.register.device.soon': 'Información de Dispositivos Próximos a Expirar',
  'company.register.device.expire': 'Información de Dispositivos Expirados',
  'company.register.company.count': 'Número de Empresas Registradas Actualmente',
  'company.register.soon.count': 'Número de Empresas Próximas a Expirar',
  'company.register.expire.count': 'Número de Empresas Expiradas',
  'company.register.device.all.count': 'Número de Dispositivos Registrados Actualmente',
  'company.register.device.soon.count': 'Número de Dispositivos Próximos a Expirar',
  'company.register.device.expire.count': 'Número de Dispositivos Expirados',
  'company.search': 'Consultar',
  'company.reset': 'Restablecer',
  'company.filter': 'Filtrar',
  'company.recharge.device.count': 'Número de Dispositivos Recargados',
  'company.renewal.time': 'Tiempo de Renovación',
  'company.renewal.duration': 'Duración de la Renovación',
  'company.card.day': 'Día',
  'company.card.month': 'Mes',
  'company.card.add.month': 'Mes',
  'company.card.year': 'Año',
  'company.card.stop': 'Deshabilitado',
  'company.card.start': 'Habilitar',
  'company.card.delete.fail': '¡Esta tarjeta de recarga ya ha sido usada y no se puede eliminar!',
  'company.card.delete.tips':
    '¿Seguro que desea eliminar la información de la tarjeta de recarga seleccionada?',
  'company.card.add.fail': 'Error al agregar tarjeta de recarga',
  'company.card.info.setting': 'Configuración de la Información de la Tarjeta de Recarga',
  'company.card.pls.buy.count': 'Por favor ingrese la cantidad de compra',
  'company.card.pls.term': 'Por favor ingrese el plazo de uso',
  'company.card.term.unit': 'Unidad de Plazo',
  'company.card.select.sale.device.model': 'Por favor seleccione el modelo del dispositivo',
  'company.card.device.model.not':
    '¡El modelo del dispositivo de la empresa está vacío, no se puede agregar información de la tarjeta de recarga!',
  'company.device.type.statistics': 'Estadísticas de Tipo de Dispositivo',
  'company.sales.man.statistics': 'Estadísticas de Vendedores',
  'company.sales.device.statistics': 'Estadísticas de Dispositivos Nuevos',
  'company.sales.device.count.statistics':
    'Estadísticas de Cantidad de Dispositivos Vendidos Mensualmente',
  'company.sales.device.count': 'Cantidad de Dispositivos Vendidos',
  'company.new.customers.statistics': 'Estadísticas de Clientes Nuevos',
  'company.customers.register.count': 'Cantidad de Clientes Registrados',
  'company.new.customers.info.statistics': 'Estadísticas de Información de Clientes Nuevos',
  'company.system.access.statistics': 'Registro de Acceso al Sistema',
  'company.sales.man.info.statistics': 'Estadísticas de Información de Vendedores',
  'company.date': 'Fecha',
  'company.login.count': 'Número de Inicios de Sesión',
  'company.one.month.login.count':
    'Estadísticas de Frecuencia de Inicio de Sesión en el Sistema en el Último Mes',
  'company.add.set': 'Configuración de la Empresa',
  'company.add.basics': 'Información Básica',
  'company.add.module.share': 'El móvil no puede ser compartido con otro dispositivo',
  'company.add.country.code.fail':
    '¡El código de país ingresado es incorrecto, por favor ingréselo nuevamente!',
  'company.add.pls.name': 'Ingrese el Nombre de la Empresa',
  'company.add.pls.code': 'Ingrese el Código de la Empresa',
  'company.add.pls.model': 'Ingrese el Modelo del Dispositivo',
  'company.add.wrongful.code': 'Código de empresa ilegal',
  'company.add.code.length':
    'La longitud del código de la empresa no puede ser menor a cuatro dígitos',
  'company.add.contact': 'Contacto',
  'company.add.pls.contact': 'Ingrese el Contacto',
  'company.add.contact.tel': 'Teléfono',
  'company.add.pls.contact.tel': 'Ingrese el Teléfono',
  'company.add.email': 'Correo Electrónico',
  'company.add.pls.email': 'Ingrese el Correo Electrónico',
  'company.add.bengin.time': 'Hora de Inicio',
  'company.add.end.time': 'Hora de Finalización',
  'company.add.province': 'Provincia',
  'company.add.city': 'Ciudad',
  'company.add.pls.city': 'Ingrese la Ciudad',
  'company.add.register.time': 'Hora de Registro',
  'company.add.sale.device.model': 'Modelo del Dispositivo',
  'company.add.configure': 'Información de Configuración',
  'company.add.read.type': 'Modo de Subida de Datos',
  'company.add.omit.push.func': 'Función de Notificación',
  'company.add.create.user': 'Personal de Apertura',
  'company.add.time.zone': 'Zona Horaria',
  'company.add.time.zone.des': 'Por favor manténgalo consistente con su zona horaria local',
  'company.add.country.code': 'Código de País',
  'company.add.country.app': 'Máximo de Usuarios Móviles',
  'company.add.pls.country.code': 'Ingrese el Código de País',
  'company.add.show.device.model': 'Mostrar modelo del dispositivo',
  'company.add.no.show.device.model': 'No mostrar modelo del dispositivo',
  'company.add.data.package': 'Modo de Paquete',
  'company.add.base64.mode': 'Modo Base64',
  'company.add.no.app.push.func': 'Sin función de notificación en la aplicación',
  'company.add.app.push.func': 'Con función de notificación en la aplicación',
  'company.add.charge.or.not': 'Cobrar o no',
  'company.add.no.charge': 'Sin Cargo',
  'company.add.charge': 'Cobrar',
  'company.add.companycode.info':
    'No se puede usar Invitado o Administrador como código de empresa, ¡por favor ingréselo nuevamente!',
  'company.basics.info': 'Datos Básicos',
  'company.basics.dept.name': 'Nombre del Departamento',
  'company.basics.site.list': 'Lista de Puntos de Control',
  'company.basics.site.name': 'Nombre del Punto de Control',
  'company.basics.site.code': 'ID del Punto de Control',
  'company.basics.site.delete.tips': '¿Seguro que desea eliminar el registro?',
  'company.basics.user.list': 'Lista de Guardias',
  'company.basics.user.name': 'Nombre del Guardia',
  'company.basics.user.code': 'ID del Guardia',
  'company.basics.user.delete.tips': '¿Seguro que desea eliminar el registro?',
  'company.basics.event.list': 'Lista de Eventos',
  'company.basics.event.name': 'Nombre del Evento',
  'company.basics.event.code': 'ID del Evento',
  'company.basics.user.event.tips': '¿Seguro que desea eliminar el registro?',
  'company.device.change.end.time.success': 'Modificado exitosamente',
  'company.device.number': 'Código del Dispositivo',
  'company.device.end.time': 'Hora de Finalización',
  'company.device.belonging.enterprise': 'Empresa',
  'company.device.delete.info.tips': '¿Seguro que desea eliminar el registro?',
  'company.device.change.end.time.tips':
    '¿Seguro que desea modificar la hora de finalización del dispositivo registrado?',
  'company.device.register.count': 'Número de dispositivos registrados',
  'company.device.register.info.statistics': 'Estadísticas de dispositivos registrados',
};
