export default {
  'map.alarmcontentinfo.alarmdetail': 'Detalles de la Alarma:',
  'map.alarmcontentinfo.unkonwdetail': 'Detalles Desconocidos',
  'map.alarmcontentinfo.iknow': 'manejar',
  'map.devicecontentinfo.unkonwperson': 'Guardia Desconocido',
  'map.devicecontentinfo.track.timerange':
    'Rango de tiempo de reproducción de datos de seguimiento',
  'map.devicecontentinfo.track.speed': 'Velocidad(km/h)',
  'map.devicecontentinfo.track.begin': 'Reproducción',
  'map.devicecontentinfo.track.splitTime': 'Intervalo de tiempo entre segmentos (minutos)',
  'map.devicecontentinfo.track.splitDistance': 'Distancia de intervalo entre segmentos (metros)',
  'map.floatingtoolbar.track.replay': 'Repetir',
  'map.floatingtoolbar.track.pause': 'Pausa',
  'map.floatingtoolbar.track.resume': 'Reanudar',
  'map.floatingtoolbar.track.clear': 'Salir',
  'map.floatingtoolbar.track.resetlimit': 'Restablecer Condición',
  'map.device.deviceno': 'ID del Dispositivo',
  'map.search.nocondition.msg': '¡Por favor, ingrese los criterios de búsqueda!',
  'map.track.notime.msg': '¡Por favor, seleccione el rango de tiempo de reproducción de la pista!',
  'map.track.empty': 'No se encontraron datos de seguimiento',
  'map.site.sitename': 'Nombre del Sitio',
  'map.site.address': 'Dirección',
  'map.module.title': 'Monitoreo',
  'map.sitecontentinfon.unknowdept': 'Departamento Desconocido',
  'map.sitecontentinfon.unknowperson': 'Guardia Desconocido',
  'map.sitecontentinfon.actionhistory': 'Historial',
  'map.topcomponent.select.plan': 'Por favor, elija un plan',
  'map.topcomponent.device.onlinetime': 'Tiempo en Línea (min.)',
  'map.topcomponent.screen.full': 'Alternar pantalla completa',
  'map.realdata.table.deptname': 'Departamento',
  'map.realdata.table.sitename': 'Sitio',
  'map.realdata.table.guardname': 'Guardia',
  'map.realdata.table.guardcode': 'Dispositivo',
  'map.realdata.table.happentime': 'Tiempo',
  'map.realdata.changepage.old': 'Cambiar al mapa de monitoreo antiguo',
  'map.realdata.changepage.new': 'Cambiar al mapa de monitoreo nuevo',
  'map.realdata': 'Datos en Tiempo Real',
  'map.google.normal': 'Normal',
  'map.google.satellite': 'Satélite',
  'map.videomodal.title': 'video',
  'map.audiomodal.title': 'audio',
  'map.button.close': 'cerrar',
  'map.playback': 'Reproducción de Pista',
  'map.playback.over': 'Fin de la reproducción de la pista',
  'map.playback.mile': 'metro',
  'map.playback.timespan':
    'El período de tiempo de reproducción de la pista no puede exceder 2 días, por favor, seleccione nuevamente el rango de fechas',
  'map.load.timeout': 'Tiempo de espera de carga del mapa, la página se recargará',
  'map.cluster.more': 'Acercar para ver más marcadores...',
  'map.site.add.confirm':
    '¿Está seguro de que desea agregar todos los puntos de control bajo el plan seleccionado actualmente al mapa?',
  'map.site.del.confirm':
    '¿Está seguro de que desea eliminar todos los puntos de control bajo el plan seleccionado actualmente del mapa?',
  'map.site.del.info': 'Por favor, seleccione un plan primero',
  'map.planImg.warn.planNotImgMsg': 'El plan actual no tiene un esquema, por favor, súbalo',
  'map.planImg.upload.label': 'Importar imagen',
  'map.planImg.warn.siteNotHaveFlag': 'El sitio de inspección planificado actual no está marcado',
  'map.planImg.warn.outOfRange':
    'Marque la ubicación fuera del alcance del diagrama, por favor, vuelva a marcar',
  'map.planImg.mode.update': 'Modo de edición',
  'map.planImg.btn.addFlag': 'Nueva etiqueta',
  'map.planImg.btn.clearFlagState': 'sin puntuar',
  'map.planImg.btn.uptImg': 'Modificar imagen',
  'map.planImg.btn.delFlag': 'Eliminar etiqueta',
  'map.dataPushServer.status.running': 'Servicio en operación',
};
