export default {
  'bi.title': 'Plataforma de Visualización de Patrullaje',
  'bi.guard': 'Guardia',
  'bi.site': 'Sitio',
  'bi.source': 'Fuente',
  'bi.plan': 'Plan',
  'bi.device': 'Dispositivo',
  'bi.record': 'Registro',
  'bi.time': 'Tiempo',
  'bi.no': 'Núm.',
  'bi.record.trend': 'Tendencia del Tráfico de Datos',
  'bi.record.average': 'Promedio',
  'bi.record.upload': 'Tráfico de Subida',
  'bi.record.unit': '',
  'bi.record.list': 'Lista de Tráfico de Datos',
  'bi.qualified': 'Calificado',
  'bi.unqualified': 'Omitir',
  'bi.todo': 'Por hacer',
  'bi.qualified.rate': 'Tasa de Calificación',
  'bi.unqualified.rate': 'Tasa de Omisión',
  'bi.rate.today': 'Estadísticas de Hoy',
  'bi.attendacne.rank': 'Rango de Asistencia',
  'bi.rate.trend': 'Gráfico de Estadísticas',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
  'bi.empty': 'No hay datos aquí',
  'bi.largest.guide':
    'Por favor ajuste la ventana al tamaño máximo para una mejor experiencia en la pantalla de BI',
  'bi.largest.ignore': 'Ignorar',
};
