export default {
  'guard.title': 'Configuración de Guardia',
  'guard.btn.batch': 'Lectura por Lotes',
  'guard.btn.add': 'Agregar Guardia',
  'guard.btn.edit': 'Editar Guardia',
  'guard.btn.del': 'Eliminar Guardia',
  'guard.btn.import': 'Importar Guardia',
  'guard.btn.export': 'Exportar Guardia',
  'guard.btn.home': 'Volver al Inicio',
  'guard.table.code': 'ID de Guardia',
  'guard.table.name': 'Nombre de Guardia',
  'guard.table.operate': 'Operar',
  'guard.table.edit': 'Editar',
  'guard.app.device': 'Lista de Dispositivos',
  'guard.app.placeholder': 'Por favor, seleccione un dispositivo',
  'guard.finger.name': 'Huella Digital',
  'guard.finger.coll': 'Recolectar Huella Digital',
  'guard.finger.coll.success': 'Huella digital recolectada exitosamente',
  'guard.finger.coll.failed': 'Recolección de huella digital fallida',
  'guard.finger.download': 'Descargar Huella Digital',
  'guard.finger.download.success': 'Descarga de huella digital exitosa',
  'guard.finger.download.failed': 'Descarga de huella digital fallida',
  'guard.finger.download.deldata':
    'Descarga de huella digital fallida, antes de descargar la huella, por favor, borre los datos en el dispositivo.',
  'guard.finger.download.nofinger': 'No hay información de huella digital para descargar.',
  'guard.input.guard.placeholder': 'Por favor, ingrese el nombre de la guardia',
  'guard.finger.onlyOneLine': 'Solo se puede editar una línea a la vez',
  'guard.table.moveup': 'Mover hacia arriba',
  'guard.table.movedown': 'Mover hacia abajo',
};
