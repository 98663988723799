export default {
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  back: 'Atrás',
  'general.homeSwitch.title.old': '¿No le gusta el nuevo Panel de Control? Cambie al anterior.',
  'general.homeSwitch.title.new': '¿Pruebe el nuevo Panel de Control diseñado?',
  'general.WePatrol.qr.download.text': 'Escanee para descargar WePatrol',
  'general.WePatrol.qr.use.text':
    'Use [ {code} ] como Código de Registro para registrarse e iniciar sesión',
  'general.WePatrol.qr.login.text': 'Escanee para registrarse e iniciar sesión',
  'general.guest.alert':
    'Modo invitado mostrando datos simulados generados aleatoriamente solo como referencia...',
  'general.unhandled.alert': 'Hay {count} registro(s) pendientes de procesar...',
};
