export default {
  'user.title': 'Operador',
  'user.btn.add': 'Agregar Operador',
  'user.btn.dept': 'Ver Empresa',
  'user.btn.right': 'Asignar Permiso',
  'user.btn.init': 'Inicializar Contraseña',
  'user.btn.home': 'Volver al Inicio',
  'user.table.displayname': 'Nombre de Inicio de Sesión',
  'user.table.username': 'Nombre del Operador',
  'user.table.rolename': 'Rol',
  'user.table.operate': 'Operar',
  'user.table.edit': 'Editar',
  'user.role.title': 'Asignar Permiso',
  'user.role.label': 'Lista de Roles',
  'user.role.message': 'Por favor, seleccione un rol',
  'user.input.username.placeholder': 'Por favor, ingrese el nombre del operador',
  'user.input.displayname.placeholder': 'Por favor, ingrese el nombre de inicio de sesión',
  'user.input.email.placeholder': 'Por favor, ingrese el correo electrónico',
  'user.btn.init.confirm':
    '¿Seguro que desea restaurar la contraseña original? (contraseña inicial: 999)',
  'user.serial.title': 'Activación del Sistema',
  'user.serial.activation': 'Código de activación',
  'user.serial.verification': 'Código de verificación',
  'user.serial.verification.placeholder': 'Por favor, ingrese el código de verificación',
  'user.app.expand': 'Expansión de Cantidad de la Aplicación',
  'user.app.count': 'Cantidad',
};
