export default {
  'login.title': 'Bienvenido',
  'login.subtitle': 'Sistema Inteligente de Patrulla de Guardia',
  'login.type.login': 'Iniciar Sesión',
  'login.type.trial': 'Prueba',
  'login.companyCode': 'Código de la Empresa',
  'login.companyCode.required': 'Se requiere el código de la empresa',
  'login.userName': 'Nombre de Usuario',
  'login.userName.required': 'Se requiere el nombre de usuario',
  'login.password': 'Contraseña',
  'login.password.required': 'Se requiere la contraseña',
  'login.remember': 'Recordar Contraseña',
  'login.captcha': 'Captcha',
  'login.captcha.captchaRequired': 'Se requiere el captcha',
  'login.guest': 'Invitado',
  'login.submit': 'Iniciar Sesión',
  'login.submitapplicaiton': 'Enviar',
  'login.captcha.required': 'Por favor, complete la verificación hombre-máquina',
  'login.trial.companyName': 'Nombre de la Empresa',
  'login.trial.companyName.required': 'Ingrese el nombre de la empresa',
  'login.trial.equipment.read': 'Leer',
  'login.trial.equipment': 'Número de Dispositivo',
  'login.trial.equipment.required': 'Lea el número de dispositivo',
  'login.trial.contacts': 'Nombre de Contacto',
  'login.trial.contacts.required': 'Ingrese el nombre de contacto',
  'login.trial.contacts.phone': 'Teléfono',
  'login.trial.contacts.phone.required': 'Ingrese el teléfono',
  'login.trial.captcha': 'Código de Verificación',
  'login.trial.captcha.required': 'Ingrese el código de verificación',
  'login.trial.captcha.read': 'Enviar',
  'login.trial.city': 'Ciudad',
  'login.trial.address.en': 'Dirección',
  'login.trial.countryCode': 'Código de País',
  'login.trial.timeZone': 'Zona Horaria',
  'login.trial.city.required': 'Ingrese la ciudad',
  'login.trial.address': 'Dirección de la Empresa',
  'login.trial.address.required': 'Ingrese la dirección de la empresa',
  'login.trial.success': 'La solicitud fue exitosa',
  'login.trial.contacts.email': 'Correo Electrónico',
  'login.trial.contacts.email.required': 'Ingrese el correo electrónico',
  'login.trial.alarm.mobile':
    'El código de verificación ha sido enviado a su correo, por favor, revíselo cuidadosamente',
  'login.trial.alarm.email':
    'El código de verificación ha sido enviado a su correo, por favor, revíselo cuidadosamente',
  'login.trail.alarm.email.not.receive': '¿No recibido?',
  'login.trial.alarm.email.spam':
    'El correo puede haber sido bloqueado por su proveedor de correo con una política de seguridad incorrecta, por favor, revíselo en la carpeta de Spam',
  'login.trial.alarm.reg.mobile':
    'La información de la cuenta ha sido enviada a su correo, por favor, revísela cuidadosamente',
  'login.trial.alarm.reg.email':
    'La información de la cuenta ha sido enviada a su correo, por favor, revísela cuidadosamente',
  'login.trial.captcha.mobile.format':
    'El formato del número de teléfono móvil es incorrecto. Por favor, ingrese nuevamente',
  'login.trial.contacts.email.format':
    'El formato del número de correo electrónico es incorrecto. Por favor, ingrese nuevamente',
  'login.trial.contacts.mobile.legal': 'Formato de teléfono móvil ilegal',
  'login.trial.contacts.email.legal': 'Formato de correo electrónico ilegal',
  'login.app.title': 'Aplicación CloudPatrol+',
  'login.app.subTitle': '「 Gestión Pura de Patrulla 」',
  'login.app.desc1': 'Escanee el código QR a la izquierda para descargar la aplicación',
  'login.app.desc2': 'Escanee el código QR a la derecha para iniciar sesión en la aplicación',
  'login.app.desc3': 'Use el nombre de usuario y contraseña para iniciar sesión',
  'login.slogan': 'Sistema de Patrulla de Guardia',
  'login.vaptcha.text': 'El plugin se está inicializando...',
  'login.nocaptcha.loading': 'Cargando...',
  'login.nocaptcha.guide': 'Deslice hacia la derecha para verificar',
  'login.nocaptcha.pass': 'Aprobado',
  'login.nocaptcha.error':
    'Vaya..., haga clic <a href="javascript:__nc.reset()">aquí</a> para reintentar',
  'login.nocaptcha.offline':
    'Error de red, haga clic <a href="javascript:__nc.reset()">aquí</a> para actualizar',
  'login.expired.exit.desc': 'Credenciales expiradas, por favor, inicie sesión nuevamente...',
  'login.companyCode.illegal': 'Código de Empresa Ilegal',
  'login.retrievePwd': 'Recuperar contraseña',
};
