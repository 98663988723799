export default {
  'content.title': 'Configuración de Contenido',
  'content.btn.add': 'Agregar Contenido',
  'content.btn.site': 'Seleccionar Punto de Control',
  'content.btn.choice': 'Agregar Opciones',
  'content.label.choice': 'Opciones de Contenido',
  'content.input.content.placeholder': 'Por favor ingrese el contenido',
  'content.input.des.placeholder': 'Por favor ingrese la descripción del contenido',
  'content.input.choice.placeholder': 'Por favor ingrese las opciones de contenido',
  'content.notification.description':
    'El título del contenido de patrullaje ya existe, por favor ingréselo nuevamente.',
  'content.notification.choice.blank':
    '¡Cuando el método de envío es de selección única o múltiple, se deben ingresar las opciones de contenido!',
  'content.table.moveup': 'Mover Arriba',
  'content.table.movedown': 'Mover Abajo',
};
