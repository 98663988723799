/* eslint-disable global-require */
import { getIntl, getLocale, addLocale, getAllLocales } from 'umi';

export const configLocale = () => {
  const providedLocales = getAllLocales();
  // 自带的语言插件无法面面俱到到支持各种语言代码，如不带地区的zh
  // 只好先手动处理一下
  // console.log('Locale -- ', getLocale());
  if (getLocale() === 'zh' && providedLocales && !providedLocales.includes('zh')) {
    addLocale('zh', require('@/locales/zh-CN').default, {
      momentLocale: 'zh-cn',
      antd: require('antd/es/locale/zh_CN').default,
    });
  }

  // 强制进行语言地区匹配
  // TODO: 如果某一地区的语言有特殊要求，需要进一步创建对应的语言匹配包
  if (getLocale().startsWith('en') && providedLocales && !providedLocales.includes(getLocale())) {
    addLocale(getLocale(), require('@/locales/en-US').default, {
      momentLocale: 'en-us',
      // TODO: 强制使用了en_US，对antd组件翻译是否会有影响？
      antd: require('antd/es/locale/en_US').default,
    });
  }

  if (getLocale().startsWith('ru') && providedLocales && !providedLocales.includes(getLocale())) {
    addLocale(getLocale(), require('@/locales/ru-RU').default, {
      // TODO: momentLocal到底是大写还是小写...
      momentLocale: 'ru-RU',
      antd: require('antd/es/locale/ru_RU').default,
    });
  }

  if (getLocale().startsWith('fr') && providedLocales && !providedLocales.includes(getLocale())) {
    addLocale(getLocale(), require('@/locales/fr-FR').default, {
      momentLocale: 'fr-FR',
      antd: require('antd/es/locale/fr_FR').default,
    });
  }

  if (getLocale().startsWith('el') && providedLocales && !providedLocales.includes(getLocale())) {
    addLocale(getLocale(), require('@/locales/el-GR').default, {
      momentLocale: 'el-GR',
      antd: require('antd/es/locale/el_GR').default,
    });
  }

  // Safari浏览器在中文环境下 getAllLocales返回值包括zh-CN，但是getLocale居然返回了zh-cn，什么鬼
  // 强行兼容一下
  if (
    getLocale().toLowerCase() === 'zh-cn' &&
    providedLocales &&
    !providedLocales.includes('zh-cn')
  ) {
    addLocale('zh-cn', require('@/locales/zh-CN').default, {
      momentLocale: 'zh-cn',
      antd: require('antd/es/locale/zh_CN').default,
    });
  }

  if (getLocale().startsWith('es') && providedLocales && !providedLocales.includes(getLocale())) {
    addLocale(getLocale(), require('@/locales/es-ES').default, {
      momentLocale: 'es-ES',
      // TODO: 强制使用了en_US，对antd组件翻译是否会有影响？
      antd: require('antd/es/locale/es_ES').default,
    });
  }
};

// https://github.com/umijs/umi/issues/5016
export default (id, params, defaultMsg) => {
  try {
    if (getLocale && typeof getLocale === 'function') {
      const localeIntl = getIntl(getLocale());
      if (!localeIntl.messages.hasOwnProperty(id)) {
        return 'Unknow';
      }
      const tmpVal = localeIntl.messages[id];
      if (tmpVal === undefined || tmpVal === null || tmpVal === '') {
        return '';
      }
      return localeIntl.formatMessage(
        {
          id,
          defaultMessage: defaultMsg || id,
        },
        params,
      );
    }
    return '';
  } catch (e) {
    return '';
  }
};
