export default {
  'dept.title': 'Nombre de la Empresa',
  'dept.btn.add': 'Agregar Empresa',
  'dept.btn.addSubsidiary': 'Agregar Subsidiaria',
  'dept.btn.edit': 'Editar Empresa',
  'dept.btn.del': 'Eliminar Empresa',
  'dept.btn.home': 'Volver al Inicio',
  'dept.input.dept.placeholder': 'Por favor ingrese el nombre de la empresa',
  'dept.input.email.btn': 'agregar',
};
