export default {
  'query.condition.dept': 'Nombre de la Empresa',
  'query.condition.guard': 'Nombre de la Guardia',
  'query.condition.site': 'Nombre del Punto de Control',
  'query.condition.event': 'Nombre del Evento',
  'query.condition.plan': 'Nombre del Plan',
  'query.condition.planguard': 'Guardia del Plan',
  'query.condition.device': 'Código del Dispositivo',
  'query.condition.begin': 'Hora de Inicio',
  'query.condition.end': 'Hora de Finalización',
  'query.condition.time': 'Tiempo de Patrullaje',
  'query.condition.alarmtype': 'Tipo de Alarma',
  'query.input.placeholder.site': 'Por favor, seleccione un punto de control',
  'query.input.placeholder.guard': 'Por favor, seleccione una guardia',
  'query.input.placeholder.device': 'Por favor, seleccione un dispositivo',
  'query.input.placeholder.plan': 'Por favor, seleccione un plan',
  'query.input.placeholder.alarm': 'Por favor, seleccione un tipo de alarma',
  'query.history.title': 'Datos Históricos',
  'query.history.btn.reanalysis': 'Reanálisis',
  'query.app.remark': 'Contenido y Notas',
  'query.app.multimedia': 'Multimedia',
  'query.app.view': 'Ver',
  'query.app.notes': 'Notas',
  'query.app.view.pict': 'Ver Fotos',
  'query.app.view.video': 'Ver Video',
  'query.app.view.audio': 'Ver Audio',
  'query.app.options': 'Opciones de Contenido',
  'query.app.state': 'Estado',
  'query.app.notentered': 'No ingresado',
  'query.app.notselected': 'No seleccionado',
  'query.app.selected': 'Seleccionado',
  'query.app.site.errspan':
    'El rango de error del punto actual está más allá del rango especificado, el punto de control puede no ser válido.',
  'query.app.site.time': 'El tiempo de patrullaje actual puede haber sido modificado.',
  'query.app.site.both':
    'El rango de error del punto actual está más allá del rango especificado, el punto de control puede no ser válido y el tiempo de patrullaje actual puede haber sido modificado.',
  'query.alarm.title': 'Datos de Alarma',
  'query.alarm.btn.del': 'Eliminar Datos',
  'query.alarm.type.all': 'Todas las Alarmas',
  'query.alarm.type.manual': 'Alarma Manual',
  'query.alarm.type.lowvoltage': 'Alarma de Bajo Voltaje',
  'query.alarm.type.incline': 'Alarma de Inclinación',
  'query.alarm.type.static': 'Alarma Estática',
  'query.alarm.type.impact': 'Alarma de Impacto',
  'query.alarm.type.open': 'Alarma de Demolición',
  'query.alarm.info': 'Información de Alarma',
  'query.alarm.time': 'Hora de la Alarma',
  'query.alarm.position': 'Posición de la Alarma',
  'query.omit.title': 'Datos de Omisión',
  'query.omit.rangetime': 'Desde Hasta',
  'query.chart.title': 'Estadísticas',
  'query.chart.table.plantimes': 'Veces del Plan',
  'query.chart.table.arrivedtimes': 'Veces de Llegada',
  'query.chart.table.omittimes': 'Veces de Omisión',
  'query.chart.table.arrivedrate': 'Tasa de Llegada',
  'query.chart.table.omitrate': 'Tasa de Omisión',
  'query.chart.table.summary': 'Resumen de Datos',
  'query.chart.tab.data': 'Datos Estadísticos',
  'query.chart.tab.chart': 'Visualización de Gráficos',
  'query.chart.tab.chart.column': 'Gráficos de Columnas',
  'query.chart.tab.chart.bar': 'Gráficos de Barras',
  'query.chart.tab.chart.line': 'Gráficos de Líneas',
  'query.chart.tab.omit': 'Datos de Omisión',
  'query.chart.condition.type': 'Tipo de Estadísticas',
  'query.chart.condition.dept': 'Estadísticas por Empresa',
  'query.chart.condition.guard': 'Estadísticas por Guardia',
  'query.chart.condition.site': 'Estadísticas por Punto de Control',
  'query.chart.condition.plan': 'Estadísticas por Plan',
  'query.raw.title': 'Datos Originales',
  'query.raw.table.card': 'ID de Tarjeta',
  'query.raw.table.type': 'Tipo de Tarjeta',
  'query.raw.table.name': 'Nombre de Tarjeta',
  'query.raw.table.unknow': 'Tarjeta Desconocida',
  'query.raw.table.event': 'Tarjeta de Evento',
  'query.raw.table.guard': 'Tarjeta de Guardia',
  'query.raw.table.site': 'Tarjeta de Punto de Control',
  'query.raw.table.time': 'Tiempo de Verificación',
  'query.raw.btn.unknow': 'Conversión de Etiqueta Desconocida',
  'query.raw.btn.site': 'Convertir a Punto de Control',
  'query.raw.btn.guard': 'Convertir a Guardia',
  'query.raw.btn.event': 'Convertir a Evento',
  'query.log.title': 'Información de Registro',
  'query.log.table.operate': 'Interfaz de Operación',
  'query.log.table.content': 'Contenido de Operación',
  'query.log.table.time': 'Tiempo de Operación',
  'query.log.table.user': 'Operador',
  'query.log.form.dept': 'Configuración de Empresa',
  'query.log.form.guard': 'Configuración de Guardia',
  'query.log.form.site': 'Configuración de Puntos de Control',
  'query.log.form.plan': 'Configuración de Plan',
  'query.log.form.read': 'Leer Datos',
  'query.calendar.january': 'Enero',
  'query.calendar.february': 'Febrero',
  'query.calendar.march': 'Marzo',
  'query.calendar.april': 'Abril',
  'query.calendar.may': 'Mayo',
  'query.calendar.june': 'Junio',
  'query.calendar.july': 'Julio',
  'query.calendar.august': 'Agosto',
  'query.calendar.september': 'Septiembre',
  'query.calendar.october': 'Octubre',
  'query.calendar.november': 'Noviembre',
  'query.calendar.december': 'Diciembre',
  'query.calendar.year': '-',
  'query.calendar.year1': '',
  'query.calendar.month': '',
  'query.report.condition.type': 'Tipo de Datos',
  'query.report.condition.all': 'Todos los Datos',
  'query.report.condition.normal': 'Datos Normales',
  'query.report.condition.arrived': 'Datos de Llegada',
  'query.report.condition.todo': 'Datos por Hacer',
  'query.report.table.alltimes': 'Todas las Veces',
  'query.report.table.patroltimes': 'Veces de Patrullaje',
  'query.report.table.topatrol': 'Veces a Patrullar',
  'query.report.table.sequence.right': 'Secuencia Correcta',
  'query.report.table.sequence.err': 'Error de Secuencia',
  'query.report.btn.export.summary': 'Exportar Informe Resumido',
  'query.report.btn.export.ordinary': 'Exportar Informe Ordinario',
  'query.report.btn.export.summaryname': 'Datos del Informe Resumido',
  'query.report.btn.export.ordinaryname': 'Datos del Informe Ordinario',
  'query.report.table.step': 'Paso',
  'query.report.btn.del': 'Eliminar Datos del Informe',
  'query.history.analysis': '¿Confirmar para analizar los datos?',
  'query.content.fold': 'Contraer Todo',
  'query.content.expand': 'Expandir Todo',
  'query.report.table.status': 'Tipo de Inspección',
};
