export default {
  'device.title': 'Configuración de Parámetros',
  'device.system': 'Configuración del Sistema',
  'device.device': 'Configuración del Dispositivo',
  'device.modal.title': 'Aviso',
  'device.modal.ok': 'Aceptar',
  'device.modal.edit': 'Editar',
  'device.modal.save': 'Guardar',
  'device.modal.del': 'Eliminar',
  'device.modal.cancel': 'Cancelar',
  'device.modal.operate': 'Operar',
  'device.modal.setsuccess': 'Configuración Exitosa',
  'device.modal.setsuccessandreminder':
    'Configuración Exitosa, las huellas solo están disponibles después de la descarga',
  'device.modal.seterr': 'Error en la Configuración',
  'device.modal.delconfirm': '¿Seguro que desea eliminar los datos seleccionados?',
  'device.modal.delsuccess': 'Eliminado exitosamente.',
  'device.modal.delerr': 'Error al eliminar',
  'device.system.basic': 'Parámetro Básico',
  'device.system.basic.event': 'Evento de Salida',
  'device.system.basic.cycle': 'Plan Especial',
  'device.system.basic.cost': 'Tiempo de Patrullaje',
  'device.system.basic.cost.des': 'Si se muestra el tiempo de patrullaje en el informe',
  'device.system.basic.stay': 'Tiempo Restante',
  'device.system.basic.stay.des': 'Si se muestra el tiempo restante en el informe',
  'device.system.basic.order': 'Patrullaje en Secuencia',
  'device.system.basic.order.des':
    'Si cada punto se muestra en el informe y se ejecuta en el orden establecido en el plan',
  'device.system.basic.date': 'Formato de Fecha',
  'device.system.basic.enable': 'Habilitar',
  'device.system.basic.disenable': 'Deshabilitar',
  'device.device.btn.parameter': 'Configuración del Dispositivo',
  'device.device.btn.parameter.remote': 'Configuración de Parámetros Remotos del Dispositivo',
  'device.device.btn.delalldevicedata': '¿Confirmar borrar los datos del dispositivo?',
  'device.device.btn.delalldeviceclock': 'Confirmar eliminar el reloj de alarma en el dispositivo',
  'device.device.btn.deldevicedata': 'Borrar Datos del Dispositivo',
  'device.device.btn.conn': 'Configuración de Comunicación',
  'device.device.btn.delfinger': 'Eliminar Huellas',
  'device.device.btn.init': 'Inicialización del Dispositivo',
  'device.device.btn.initconfirm':
    '¿Seguro que desea restaurar su dispositivo a la configuración de fábrica?',
  'device.device.MsgNoReader': 'Por favor conecte el dispositivo.',
  'device.device.MsgNoFount': 'Dispositivo no encontrado, por favor confirme.',
  'device.device.MsgWriteFailed': 'Error al escribir',
  'device.device.MsgOpenFailed': 'Error al abrir el dispositivo',
  'device.device.MsgReadFailed': 'Error al leer los datos.',
  'device.device.MsgConnFailed': 'Error al conectar el dispositivo.',
  'device.device.MsgTypeFailed': 'Tipo de dispositivo incorrecto',
  'device.device.noparam': 'El dispositivo actual no necesita configurar parámetros',
  'device.device.init': '¿Seguro que desea restaurar su dispositivo a la configuración de fábrica?',
  'device.system.init': 'Inicialización del Sistema',
  'device.btn.download.all': 'Descargar todos los datos',
  'device.btn.download.select': 'Descargar datos seleccionados',
  'device.guardname': 'Nombre del Guardia',
  'device.guardname.place': 'Por favor ingrese el nombre',
  'device.guardname.des': 'Mostrar en la pantalla',
  'device.clock': 'Configuración del Reloj',
  'device.clock.sn': 'Núm.',
  'device.clock.name': 'Hora del Reloj',
  'device.clock.add': 'Agregar Reloj',
  'device.clock.del': 'Eliminar Reloj',
  'device.clock.down': 'Descargar',
  'device.clock.delDown': 'Borrar Descarga',
  'device.clock.exittime': 'La hora del reloj ingresada ya existe, por favor ingrésela nuevamente',
  'device.clock.nulltime': 'Por favor seleccione la hora del reloj',
  'device.clock.max':
    'La alarma solo puede descargar un máximo de 200 grupos, por favor seleccione nuevamente',
  'device.flashlight.closemode': 'Modo de Apagado de la Linterna',
  'device.flashlight.closemode.dafault': 'Predeterminado',
  'device.flashlight.closemode.20': 'Apagar la linterna automáticamente después de 20 segundos.',
  'device.flashlight.closemode.auto': 'Apagar Automáticamente',
  'device.flashlight.closemode.manual': 'Apagar Manualmente',
  'device.flashlight.closemode.closetime': 'Hora de Cierre',
  'device.alarm.title': 'Botón de Alarma',
  'device.alarm.choice1': 'Presione el botón 1 segundo para enviar la alarma',
  'device.alarm.choice2': 'Presione el botón 2 segundos para enviar la alarma',
  'device.alarm.choice3': 'Presione el botón 3 segundos para enviar la alarma',
  'device.alarm.choice4': 'Presione el botón 4 segundos para enviar la alarma',
  'device.alarm.choice5': 'Presione el botón 5 segundos para enviar la alarma',
  'device.alarm.choice6': 'Presione el botón 6 segundos para enviar la alarma',
  'device.alarm.choice7': 'Presione el botón 7 segundos para enviar la alarma',
  'device.alarm.choice8': 'Presione el botón 8 segundos para enviar la alarma',
  'device.alarm.choice9': 'Presione el botón 9 segundos para enviar la alarma',
  'device.alarm.choice10': 'Presione el botón 10 segundos para enviar la alarma',
  'device.alarm.choice11': 'Presione el botón {count} segundos para enviar la alarma',
  'device.shuttype': 'Modo de Apagado',
  'device.shuttype.auto': 'Apagar Automáticamente',
  'device.shuttype.manual': 'Apagar Manualmente',
  'device.shuttype.time': 'Tiempo de Apagado',
  'device.shuttype.timeandunit': 'Tiempo de Apagado (minutos)',
  'device.shuttype.timeandunits': 'Tiempo de Apagado (segundos)',
  'device.shuttype.autotime': 'Hora de Cierre',
  'device.shuttype.unit': 'Unidad',
  'device.shuttype.unit.minute': 'Minutos',
  'device.shuttype.unit.seconds': 'Segundos',
  'device.screen.resolution': 'Resolución',
  'device.screen.recordtime': 'Tiempo de Grabación (s)',
  'device.screen.number': 'Número del Centro de SMS',
  'device.drop.meter1': 'Caída libre de un metro',
  'device.drop.meter2': 'Caída libre de dos metros',
  'device.drop.meter3': 'Caída libre de tres metros',
  'device.step.state': 'Estado del Podómetro',
  'device.impact.state': 'Estado de la Alarma de Impacto',
  'device.impact.incline': 'Estado de la Alarma de Inclinación',
  'device.impact.incline.param1': 'Parámetro 1 de la Alarma de Inclinación',
  'device.impact.incline.param2': 'Parámetro 2 de la Alarma de Inclinación',
  'device.impact.incline.param3': 'Parámetro 3 de la Alarma de Inclinación',
  'device.impact.incline.angle': 'Ángulo',
  'device.impact.condition': 'Condición de la Alarma de Impacto',
  'device.impact.static.param1': 'Parámetro 1 de la Alarma Estática',
  'device.impact.static.param2': 'Parámetro 2 de la Alarma Estática',
  'device.impact.static.param3': 'Parámetro de Alarma Estática 3',
  'device.impact.static.sensitivity': 'Sensibilidad',
  'device.static.condition': 'Estado de Alarma Estática',
  'device.clock.func': 'Función del Reloj',
  'device.clock.h2.ipmode': 'Modo de Comunicación',
  'device.clock.h2.dynamicip': 'IP Dinámica',
  'device.clock.h2.staticip': 'IP Estática',
  'device.clock.h2.ipaddress': 'Dirección IP',
  'device.clock.h2.subnet': 'Máscara de Subred',
  'device.clock.h2.gateway': 'Puerta de Enlace',
  'device.clock.h2.wifiname': 'Nombre del WiFi',
  'device.clock.h2.wifipwd': 'Contraseña del WiFi',
  'device.clock.h2.wififunc': 'Función del WiFi',
  'device.clock.nullwifiname': 'Por favor, ingrese el nombre del WiFi',
  'device.clock.nullwifipwd': 'Por favor, ingrese la contraseña del WiFi',
  'device.clock.h2.interval': 'Intervalo de Recordatorio de Alarma (segundos)',
  'device.clock.h2.times': 'Número de Recordatorio de Alarma',
  'device.clock.h2.intervaltimes': 'Intervalo de Tiempo (segundos)',
  'device.call.type': 'Indicaciones de Llamada',
  'device.call.type.mute': 'Silenciar',
  'device.call.type.vibration': 'Vibración',
  'device.call.type.ring': 'Sonar',
  'device.call.type.both': 'Vibración y Sonido',
  'device.voice.state': 'Función de Voz',
  'device.voice.volume': 'Volumen',
  'device.voice.volume.high': 'Alto',
  'device.voice.volume.middle': 'Medio',
  'device.voice.volume.low': 'Bajo',
  'device.finger.state': 'Función de Huella Digital',
  'device.finger.verifyone': 'Verificación una vez',
  'device.finger.verifycard': 'Verificación al leer el punto de control',
  'device.finger.stitching': 'Veces de Unión de Huella Digital',
  'device.finger.confirmdel': '¿Confirmar eliminar toda la información de huellas digitales?',
  'device.devicedata.deldatafirst':
    'Eliminación de huella digital fallida, antes de eliminar la huella, por favor, borre los datos en el dispositivo.',
  'device.devicedata.confirmdel': '¿Confirmar borrar los datos en el dispositivo?',
  'device.temperature.display': 'Modo de Temperatura',
  'device.temperature.celsius': 'Celsius',
  'device.temperature.fahrenheit': 'Fahrenheit',
  'device.gps.working': 'Modo de Trabajo',
  'device.gps.patrol': 'Modo de Patrulla',
  'device.gps.coll': 'Modo de Recolección de Puntos de Control',
  'device.gps.interval': 'Tiempo(s)',
  'device.gps.collgps': 'Tiempo para Recolectar Posición GPS',
  'device.conn.title': 'Configuración de Comunicación',
  'device.conn.type': 'Conexión',
  'device.conn.ip': 'Conectar por dirección IP',
  'device.conn.domain': 'Conectar por dominio IP',
  'device.conn.domain.name': 'Dominio',
  'device.conn.port': 'Puerto',
  'device.conn.isp': 'ISP',
  'device.conn.mobile': 'China Mobile',
  'device.conn.unicom': 'China Unicom',
  'device.conn.otherisp': 'Otro ISP',
  'device.conn.user': 'Usuario',
  'device.conn.pwd': 'Contraseña',
  'device.send.mode': 'Modo de Transferencia',
  'device.send.auto': 'Enviar Datos Automáticamente',
  'device.send.manu': 'Enviar Datos Manualmente',
  'device.send.func': 'Botón de Enviar',
  'device.send.func1': 'Presione el botón 1 segundo para enviar datos',
  'device.send.func2': 'Presione el botón 2 segundos para enviar datos',
  'device.send.func3': 'Presione el botón 3 segundos para enviar datos',
  'device.send.func4': 'Presione el botón 4 segundos para enviar datos',
  'device.send.func5': 'Presione el botón 5 segundos para enviar datos',
  'device.send.func6': 'Presione el botón 6 segundos para enviar datos',
  'device.send.func7': 'Presione el botón 7 segundos para enviar datos',
  'device.send.func8': 'Presione el botón 8 segundos para enviar datos',
  'device.send.func9': 'Presione el botón 9 segundos para enviar datos',
  'device.send.func10': 'Presione el botón 10 segundos para enviar datos',
  'device.send.func11': 'Presione el botón {count} segundos para enviar datos',
  'device.read.data.loading': 'Cargando...',
  'device.read.data.blank': 'No hay datos en el dispositivo.',
  'device.read.data.success': 'Lectura exitosa, el total de registros es:',
  'device.timing.success': 'Temporización exitosa, código del dispositivo:',
  'device.timing.failed': 'Temporización fallida',
  'device.read.failed': 'Lectura fallida',
  'device.serverTime.error': 'Error al obtener la hora del servidor',
  'device.timing.devicetype': 'Tipo de Dispositivo:',
  'device.status.disconnect': 'Dispositivo no encontrado',
  'device.status.disconnect.desc': 'Por favor, conecte su dispositivo vía USB',
  'device.socket.stop': 'El controlador de comunicación no está en ejecución, por favor, inícielo',
  'device.socket.stop.or': 'o',
  'device.socket.download': 'descargar',
  'device.socket.downloading':
    'El controlador se está descargando ahora, puede instalarlo cuando esté completo',
  'device.socket.exist': 'El controlador está en ejecución',
  'device.socket.exist.desc':
    'El controlador ya está descargado y en ejecución, ¿desea descargarlo de nuevo?',
  'device.socket.busy': 'El controlador está ocupado, por favor, intente de nuevo más tarde...',
  'device.socket.timeout': 'Tiempo de espera del controlador...',
  'device.register.list': 'Tipos de dispositivos registrados',
  'device.register.list.delete': '¿Seguro que desea eliminar este tipo de dispositivo registrado?',
  'device.unregister.title': 'Tipo de dispositivo no registrado',
  'device.unregister.content':
    'Tipo de dispositivo no registrado {type}, ¿desea registrarlo ahora?',
  'device.register.success': 'Tipo de dispositivo registrado exitosamente',
  'device.btn.add': 'Agregar Dispositivo',
  'device.btn.active': 'Activar Dispositivo',
  'device.btn.replace': 'Reemplazar Dispositivo',
  'device.btn.remark': 'Editar Observación',
  'device.btn.param': 'Configuración de Parámetros Remotos',
  'device.btn.home': 'Volver al Inicio',
  'device.table.device.code': 'Código del Dispositivo',
  'device.table.device.code.des': 'Por favor, ingrese el código del dispositivo',
  'device.table.device.type': 'Tipo de Dispositivo',
  'device.table.device.time': 'Hora de Registro',
  'device.table.device.account': 'Cuenta',
  'device.table.device.name': 'Nombre',
  'device.table.device.extra': 'Observación',
  'device.table.device.range': 'Hora de inicio y fin',
  'device.table.card.purchase': 'Cantidad de Compra',
  'device.table.card.Remain': 'Cantidad Restante',
  'device.table.card.time': 'Tiempo de Uso',
  'device.table.card.purchasetime': 'Hora de Compra',
  'device.table.card.year': 'Año',
  'device.table.card.month': 'Mes',
  'device.table.card.date': 'Día',
  'device.modal.remark.title': 'Observación',
  'device.modal.remark.placeholder': 'Por favor, ingrese una observación',
  'device.modal.remark.tip':
    'Si ingresa el nombre de la empresa, el operador bajo esta podrá ver la información del dispositivo',
  'device.notification.description.add': 'Dispositivo agregado exitosamente',
  'device.notification.description.noneed': 'El dispositivo seleccionado no necesita ser activado',
  'device.notification.description.noend':
    'El dispositivo solo puede ser activado después de casi un mes y cerca del período de validez.',
  'device.notification.description.type.wrong':
    'El dispositivo seleccionado es inconsistente con el tipo de dispositivo correspondiente al código de activación actual, y la operación de activación del dispositivo no puede completarse',
  'device.notification.description.success': 'Dispositivo activado exitosamente',
  'device.notification.description.failed': 'Activación del dispositivo fallida',
  'device.notification.description.type':
    'El tipo del dispositivo seleccionado es diferente al del dispositivo actual. Por lo tanto, el dispositivo no puede ser reemplazado. Seleccione un dispositivo del mismo tipo',
  'device.notification.description.code':
    'El dispositivo actual que se va a agregar es el mismo que el número de dispositivo seleccionado. Por lo tanto, el dispositivo no puede ser reemplazado. Necesita reemplazar el dispositivo con un número de dispositivo diferente',
  'device.notification.description.confirm':
    '¿Está seguro de reemplazar el dispositivo original? Después del reemplazo, el dispositivo original solo podrá leer los datos antes del reemplazo, los datos válidos del nuevo dispositivo comenzarán después del reemplazo.',
  'device.notification.description.unsupport':
    'Esta función no es compatible con el dispositivo de acceso actual.',
  'device.notification.description.nosite':
    'El plan no tiene puntos de control, por favor, configure los puntos de control primero.',
  'device.notification.description.noguard':
    'No hay guardia que se pueda descargar. Por favor, configure el guardia primero.',
  'device.notification.description.noplan':
    'No hay plan que se pueda descargar. Por favor, configure el guardia primero.',
  'device.notification.description.clearbook':
    'No se ha seleccionado ningún registro para descargar. Si no se selecciona ningún registro para descargar, la información de la libreta de direcciones en el dispositivo se borrará. ¿Desea continuar con esta operación?',
  'device.notification.description.unselect':
    'Por favor, seleccione primero el dispositivo a operar',
  'device.device.btn.parameterRead': 'Leer parámetros del dispositivo',
  'device.title.parameter': 'Visualización de parámetros del dispositivo',
  'device.card.readInterval': 'Intervalo de lectura de tarjeta (segundos)',
  'device.flashlight.touchTime': 'Tiempo del botón de la linterna (segundos)',
  'device.send.interval': 'Intervalo de envío automático (segundos)',
  'device.close.screenTime': 'Tiempo de apagado de pantalla (segundos)',
  'device.h2.responseTime': 'Tiempo de confirmación de recepción (segundos)',
  'device.new.prompt':
    'Hemos detectado que la versión de su componente de comunicación es demasiado baja. Para evitar afectar el uso normal, por favor, descargue la nueva versión del componente de comunicación! (Desinstale antes de instalar)',
  'device.input.yes.ipaddress': 'Por favor, ingrese la dirección IP correcta',
  'device.table.device.status': 'Estado del dispositivo',
  'device.device.status.using': 'En uso',
  'device.device.status.expire': 'Expirado',
  'device.device.status.replace': 'Ser reemplazado',
};
