export default {
  'site.title': 'Configuración del Punto de Control',
  'site.btn.batch': 'Lectura por Lotes',
  'site.btn.add': 'Agregar Punto de Control',
  'site.btn.addgps': 'Agregar Punto de Control GPS',
  'site.btn.addcoll': 'Agregar Punto de Control de Recolección',
  'site.btn.edit': 'Editar',
  'site.btn.del': 'Eliminar Punto de Control',
  'site.btn.download': 'Descargar Datos',
  'site.btn.import': 'Importar Punto de Control',
  'site.btn.export': 'Exportar Punto de Control',
  'site.btn.assign': 'Asignar Contenido',
  'site.btn.move': 'Transferir Punto de Control',
  'site.btn.home': 'Volver al Inicio',
  'site.btn.rnd': 'Puntos de control asignados aleatoriamente',
  'site.btn.rnd.del': 'Eliminar todos los puntos de control',
  'site.modal.position': 'Posición Geográfica',
  'site.table.code': 'ID del Punto de Control',
  'site.table.name': 'Nombre del Punto de Control',
  'site.table.type': 'Tipo de Punto de Control',
  'site.table.longitude': 'Longitud',
  'site.table.latitude': 'Latitud',
  'site.table.range': 'Rango de Error (m)',
  'site.table.voice': 'Voz',
  'site.table.site': 'Posición',
  'site.table.app.content': 'Título del Contenido',
  'site.table.app.des': 'Descripción del Contenido',
  'site.table.app.submission': 'Método de Envío',
  'site.table.app.qr': 'Código QR',
  'site.table.app.bluetooth': 'Bluetooth',
  'site.table.app.em': 'Código EM',
  'site.table.app.single': 'Único',
  'site.table.app.multiple': 'Múltiple',
  'site.table.app.enter': 'Entrar',
  'site.table.app.colluser': 'Recolectores',
  'site.table.app.colltime': 'Tiempo de Recolección',
  'site.table.voice.clear': 'Borrar Voz en el Dispositivo',
  'site.table.voice.clear.success': 'Voz borrada exitosamente',
  'site.table.voice.clear.failed': 'Error al borrar la voz',
  'site.table.voice.clear.confirm': '¿Confirmar borrar la voz?',
  'site.table.edit': 'Editar',
  'site.input.site.placeholder': 'Por favor, ingrese el nombre del punto de control',
  'site.input.code.placeholder': 'Por favor, ingrese el ID del punto de control',
  'site.Transfer.Unselected': 'Voces no seleccionadas',
  'site.Transfer.selected': 'Voces seleccionadas',
  'site.headerTitle.GPS': 'Todos los Puntos de Control GPS',
  'site.headerTitle.All': 'Lista de puntos de control de voz y GPS configurados',
  'site.notification.filtercode':
    'Los registros con códigos de punto de control duplicados han sido filtrados',
  'site.notification.filtername':
    'Los registros con nombres de punto de control duplicados han sido filtrados',
  'site.notification.download.success': 'Descarga de datos exitosa',
  'site.notification.mostsite': 'No se pueden seleccionar más de 1000 puntos de control',
  'site.notification.mostgpssite': 'No se pueden seleccionar más de 500 puntos de control GPS',
  'site.notification.delposition':
    '¿Está seguro de que desea eliminar las coordenadas de ubicación en el mapa?',
  'site.table.moveup': 'Mover Arriba',
  'site.table.movedown': 'Mover Abajo',
  'site.hasBindPlan.delconfirm':
    'La ubicación que desea eliminar ya está vinculada a un plan de inspección. ¿Aún desea eliminarla?',
};
