export default {
  'book.title': 'Contactos',
  'book.btn.add': 'Agregar Contactos',
  'book.btn.import': 'Importar Contacto',
  'book.btn.export': 'Exportar Contacto',
  'book.table.name': 'Nombre del Contacto',
  'book.table.tel': 'Número de Teléfono del Contacto',
  'book.input.name': 'Por favor ingrese el nombre del contacto.',
  'book.input.tel': 'Por favor ingrese el número de teléfono del contacto.',
  'book.table.moveup': 'Mover Arriba',
  'book.table.movedown': 'Mover Abajo',
};
