export default {
  'common.table.batchdel': 'Eliminar en Lote',
  'common.table.del': 'Eliminar',
  'common.table.moveup': 'Mover Arriba',
  'common.table.movedown': 'Mover Abajo',
  'common.table.cancel': 'Cancelar',
  'common.table.selected': 'Seleccionado',
  'common.table.item': 'Elementos',
  'common.btn.success': 'Operación Exitosa',
  'common.btn.download.success': 'Descarga de datos exitosa',
  'common.btn.download.failed': 'Error en la descarga de datos',
  'common.btn.confirm': 'Confirmar',
  'common.btn.cancel': 'Cancelar',
  'common.btn.close': 'Cerrar',
  'common.btn.template': 'Descargar plantilla',
  'common.btn.import.label': 'Nombre del Archivo',
  'common.btn.export.excel': 'Exportar Excel',
  'common.btn.export.pdf': 'Exportar Pdf',
  'common.btn.import.title': 'Haga clic para Subir',
  'common.btn.home': 'Volver al Inicio',
  'common.message.prompt': 'aviso',
  'common.placeholder.message': 'Por favor ingrese',
  'common.device.nodata': 'No hay datos en el dispositivo',
  'common.notification.description.nodata':
    'No se encontraron registros coincidentes, por favor cambie la condición de búsqueda.',
  'common.notification.description.batchsuccess':
    'Lectura en lote exitosa, el total de registros es:',
  'common.notification.description.records': 'El número de registros ingresados es:',
  'common.notification.description.required': 'Este ítem es requerido',
  'common.notification.query.range':
    'El rango de tiempo de la consulta no puede exceder los 60 días. Por favor, seleccione nuevamente los criterios de búsqueda.',
  'common.websocket.version':
    'La versión actual del controlador es relativamente baja. ¡Por favor descargue la última versión nuevamente!',
};
