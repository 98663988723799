export default {
  'alarm.notification.title': 'Notificación de Alarma',
  'alarm.notification.desc': 'Se recibió(n) {count} alarma(s), por favor revise...',
  'alarm.notification.view': 'Detalles',
  'alarm.notification.ignore': 'Ignorar',
  'alarm.notification.list': 'Alarmas no leídas',
  'alarm.notification.readAll': 'Leer todo',
  'alarm.notification.readAll.confirm': '¿Confirmar leer todas las alarmas?',
  'alarm.notification.read.title': 'Aviso',
  'alarm.notification.read.done': 'La alarma ha sido procesada',
  'alarm.notification.read.error': 'Error al manejar la(s) alarma(s)',
  'alarm.manual': 'Alarma Manual',
  'alarm.low': 'Alarma de Baja Batería',
  'alarm.omit': 'Alarma de Omisión',
  'alarm.tilt': 'Alarma de Inclinación',
  'alarm.motionless': 'Alarma de Inmovilidad',
  'alarm.collision': 'Alarma de Colisión',
  'alarm.dismantle': 'Alarma de Desmontaje',
  'alarm.out': 'Alarma de Bucle',
  'alarm.in': 'Alarma de Anillo',
  'alarm.choice.no': 'Sin aviso de alarma',
  'alarm.choice.web': 'Alerta solo en la página web',
  'alarm.choice.app': 'Alarma solo en dispositivo móvil',
  'alarm.choice.all': 'Alertas en web y dispositivo móvil',
  'alarm.notification.title.desc':
    'Si la función de recordatorio de datos de alarma está incluida en la página web o en el terminal móvil',
};
