export default {
  'role.title': 'Rol',
  'role.btn.add': 'Agregar Rol',
  'role.btn.save': 'Guardar Rol',
  'role.btn.home': 'Volver al Inicio',
  'role.table.name': 'Nombre del Rol',
  'role.table.operate': 'Operar',
  'role.table.edit': 'Editar',
  'role.input.name.placeholder': 'Por favor, ingrese el nombre del rol',
  'role.notification.module.description': 'Por favor, seleccione un módulo primero',
  'role.notification.role.description': 'Por favor, seleccione un rol primero',
};
