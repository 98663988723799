export default {
  'dashboard.charts': 'Gráficos',
  'dashboard.charts.title.trend': 'Tendencia de la Tasa de Calificación',
  'dashboard.charts.title.rank': 'Lista de Clasificación de la Tasa de Calificación',
  'dashboard.charts.dept.placeholder': 'Por favor seleccione un departamento.',
  'dashboard.charts.rank.dept': 'Depto.',
  'dashboard.charts.rank.plan': 'Plan',
  'dashboard.charts.rank.guard': 'Guardia',
  'dashboard.charts.rank.site': 'Sitio',
  'dashboard.charts.date.today': 'Hoy',
  'dashboard.charts.date.week': 'Semana',
  'dashboard.charts.date.month': 'Mes',
  'dashboard.charts.orderby.asc': 'Ascendente',
  'dashboard.charts.orderby.desc': 'Descendente',
  'dashboard.statistics.type': 'Tipo',
  'dashboard.statistics.type.qualified': 'Calificado',
  'dashboard.statistics.type.unqualified': 'Omitir',
  'dashboard.statistics.type.todo': 'Por hacer',
  'dashboard.statistics.columnes.count': 'Cantidad',
  'dashboard.statistics.columnes.rate': 'Porcentaje',
  'dashboard.statistics.title.percent': 'Estadísticas de Hoy',
  'dashboard.latestHistories.columnes.site': 'Sitio',
  'dashboard.latestHistories.columnes.guard': 'Guardia/Dispositivo',
  'dashboard.latestHistories.columnes.info': 'Evento',
  'dashboard.latestHistories.columnes.remark': 'Observación',
  'dashboard.latestHistories.columnes.time': 'Tiempo',
  'dashboard.latestHistories.columnes.extra': 'Medios',
  'dashboard.latestHistories.title.trend': 'Subidas Recientes',
  'dashboard.latestAlarms.title.alarmData': 'Alarmas Recientes',
  'dashboard.latestLogs.columnes.action': 'Pantalla y Acción',
  'dashboard.latestLogs.columnes.time': 'Tiempo',
  'dashboard.latestLogs.columnes.reader': 'Dispositivo',
  'dashboard.latestLogs.columnes.user': 'Usuario',
  'dashboard.latestLogs.title.log': 'Operaciones Recientes',
  'dashboard.card.extra.refresh': 'Actualizar',
  'dashboard.card.extra.history': 'Ir a la página de historial',
  'dashboard.card.extra.alarm': 'Ir a la página de alarmas',
  'dashboard.card.extra.log': 'Ir a la página de registros',
};
