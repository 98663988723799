export default {
  'backup.plan.input.placeholder': 'Por favor ingrese',
  'backup.input.styleErr': 'Error de formato',
  'backup.plan.title': 'Plan de respaldo',
  'backup.plan.add': 'Agregar un plan de respaldo',
  'backup.plan.edit': 'editar',
  'backup.plan.del': 'Eliminar un plan de respaldo',
  'backup.plan.table.name': 'Nombre del proyecto',
  'backup.plan.table.period': 'Tipo de ciclo',
  'backup.plan.table.periodValue': 'Número de ciclos',
  'backup.plan.table.backupTime': 'Hora de respaldo',
  'backup.plan.table.backupPath': 'Ruta de respaldo',
  'backup.plan.table.reserveDay': 'Días de retención',
  'backup.table.operate': 'operación',
  'backup.plan.input.backupPath.remark':
    'Este parámetro debe crearse con anticipación. Si no ingresa este parámetro, use la ubicación predeterminada',
  'backup.plan.period.day': 'día',
  'backup.plan.period.week': 'semanas',
  'backup.plan.period.month': 'mes',
  'backup.file.manual': 'Respaldo manual',
  'backup.file.upload': 'Importar respaldo',
  'backup.file.del': 'Eliminar un respaldo',
  'backup.file.download': 'descargar',
  'backup.file.restore': 'restaurar',
  'backup.file.table.fileName': 'Nombre del archivo',
  'backup.file.table.datasource': 'Fuente de datos',
  'backup.file.table.createTime': 'Hora de respaldo',
  'backup.file.table.fileSize': 'Tamaño del archivo',
  'backup.file.manual.filePath': 'Ruta de almacenamiento del archivo',
  'backup.file.table.isSuccess': 'Resultado de la operación',
  'backup.file.isSuccess.ok': 'éxito',
  'backup.file.isSuccess.fail': 'fallo',
  'backup.file.restore.warnInfo':
    'Nota importante: Después de usar la recuperación de archivos, el sistema borrará todos los datos y restaurará el período correspondiente del archivo, por favor considere cuidadosamente si desea restaurar.',
  'backup.file.restore.success.msg':
    'Restauración de {back} exitosa, por favor inicie sesión nuevamente',
  'backup.file.restore.auth': 'Verificación de la operación de recuperación',
  'backup.file.restore.authErr': 'Error al verificar la operación de restauración',
  'backup.file.datasource.task': 'Respaldo programado',
  'backup.file.datasource.manual': 'Respaldo manual',
  'backup.file.datasource.import': 'Importar respaldo',
};
