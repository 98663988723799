export default {
  'sound.title': 'Configuración de Voz',
  'sound.no': 'No.',
  'sound.name': 'Contenido de Voz',
  'sound.name.placeholder': 'Por favor, ingrese el contenido de voz',
  'sound.dept.placeholder': 'Por favor, seleccione la empresa',
  'sound.table.operate': 'Operaciones',
  'sound.table.edit': 'Editar',
  'sound.table.add': 'Agregar Voz',
  'sound.table.home': 'Volver al Inicio',
  'sound.table.voice': 'Generar Voz',
  'sound.table.clear': 'Borrar Voz del Dispositivo',
  'sound.table.voice.success': '¡Voz generada exitosamente!',
  'sound.table.voice.err': 'Error al generar la voz',
  'sound.table.delete': 'Eliminar',
  'sound.table.selected': 'Seleccionado',
  'sound.table.item': 'ítem',
  'sound.modal.delconfirm': '¿Está seguro de que desea eliminar los datos seleccionados?',
  'sound.table.deletesuccess': 'Eliminado exitosamente',
  'sound.modal.ok': 'Guardar',
  'sound.modal.cancel': 'Cancelar',
  'sound.modal.error': 'Operación Fallida',
  'sound.message.loading.add': 'Agregando...',
  'sound.message.loading.edit': 'Configurando...',
  'sound.genvoice.loading': 'cargando...',
  'sound.genvoice.invalidDevice': 'El dispositivo actual no soporta voz...',
};
