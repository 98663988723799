export default {
  'plan.title': 'Configuración de Plan',
  'plan.title.special': 'Configuración de Plan Especial',
  'plan.btn.add': 'Agregar Plan',
  'plan.btn.addTemporary': 'Agregar Plan Temporal',
  'plan.btn.bind': 'Asignar Dispositivo',
  'plan.btn.copy': 'Copiar Plan',
  'plan.btn.paste': 'Pegar Plan',
  'plan.btn.export': 'Exportar Plan',
  'plan.btn.batch': 'Programación por Lotes',
  'plan.btn.addshift': 'Agregar Horario',
  'plan.btn.delshift': 'Eliminar Horario',
  'plan.btn.guard': 'Configuración de Guardia',
  'plan.btn.addrest': 'Agregar Descanso',
  'plan.btn.Manu': 'Asignar Manualmente',
  'plan.modal.label.next': 'El día siguiente',
  'plan.modal.label.onetime': 'Tiempo para Patrullar Una Vez (minutos)',
  'plan.modal.label.resttime': 'Tiempo de Descanso (minutos)',
  'plan.modal.label.no': 'No',
  'plan.modal.label.day': 'día',
  'plan.modal.label.weekrest': 'Descanso Semanal',
  'plan.modal.label.specialrest': 'Descanso Especial',
  'plan.modal.label.period': 'Período de Patrullaje',
  'plan.modal.label.unit': 'Unidad',
  'plan.modal.label.plantimes': 'Veces del Plan',
  'plan.modal.label.times': 'Veces',
  'plan.modal.label.cost': 'Tiempo para Patrullar Una Ronda',
  'plan.modal.label.interval': 'Intervalo entre Dos Rondas',
  'plan.modal.label.month': 'Mes',
  'plan.modal.label.week': 'Semana',
  'plan.modal.label.hour': 'Hora',
  'plan.modal.label.minute': 'Minuto',
  'plan.modal.label.days': 'Día',
  'plan.table.schedule': 'Horario',
  'plan.table.restday': 'Descanso',
  'plan.table.monday': 'Lunes',
  'plan.table.tuesday': 'Martes',
  'plan.table.wednesday': 'Miércoles',
  'plan.table.thursday': 'Jueves',
  'plan.table.friday': 'Viernes',
  'plan.table.saturday': 'Sábado',
  'plan.table.sunday': 'Domingo',
  'plan.table.type': 'Tipo de Plan',
  'plan.table.per': 'Por',
  'plan.table.month': 'mes',
  'plan.table.patrol': 'patrullaje',
  'plan.tab.plan': 'Plan',
  'plan.tab.special': 'Plan Especial',
  'plan.tab.schedule': 'Horario de Patrullaje',
  'plan.step.basic': 'Configuración Básica',
  'plan.step.site': 'Configuración de Puntos de Control',
  'plan.step.shift': 'Configuración de Horario',
  'plan.step.next': 'Siguiente',
  'plan.step.prev': 'Anterior',
  'plan.step.submit': 'Enviar',
  'plan.step.rest': 'Configuración de Días de Descanso',
  'plan.transfer.site.select': 'Puntos de Control Seleccionados',
  'plan.transfer.site.unselect': 'Puntos de Control No Seleccionados',
  'plan.input.plan.placeholder': 'Por favor, ingrese el nombre del plan',
  'plan.notification.description.save.success': 'Plan guardado exitosamente',
  'plan.notification.description.sitefirst': 'Por favor, configure primero los puntos de control',
  'plan.notification.description.schedulefirst': 'Por favor, configure primero los horarios',
  'plan.notification.description.exitplan':
    'Los datos de este día ya han sido creados en este horario. ¿Necesita reemplazar los datos y crear nuevos datos?',
  'plan.notification.description.than24':
    'Cada tiempo de patrullaje debe estar dentro de las 24 horas.',
  'plan.notification.description.repeat': 'Tiempo repetido',
  'plan.notification.description.date':
    'La hora de finalización debe ser mayor que la hora de inicio.',
  'plan.notification.description.exitdate':
    'La fecha seleccionada ya existe, por favor seleccione nuevamente.',
  'plan.notification.description.exitdevice':
    'El dispositivo ya existe. Por favor, no lo agregue nuevamente.',
  'plan.notification.description.appuser': 'Se debe configurar la guardia.',
  'plan.notification.description.copy.success': 'Plan copiado exitosamente',
  'plan.notification.description.copyfirst': 'Por favor, copie el plan primero',
  'plan.notification.description.selfirst': 'Por favor, seleccione un plan',
  'plan.notification.description.selone': 'Por favor, seleccione un registro para operar',
  'plan.notification.description.patrolone':
    'El número de veces de patrullaje es una y el tiempo de intervalo debe establecerse en cero.',
  'plan.Modal.confirm.paste':
    '¿Está seguro de que desea pegar el plan copiado bajo la empresa actual?',
  'plan.Modal.confirm.restday': 'Por favor, seleccione un día de descanso primero.',
  'plan.shift.day.wrong':
    'El plan diario solo puede crearse para un máximo de 31 días. ¡Por favor, vuelva a ingresar el período de patrullaje!',
  'plan.shift.week.wrong':
    'El plan semanal solo puede crearse para un máximo de 4 semanas. ¡Por favor, vuelva a ingresar el período de patrullaje!',
  'plan.shift.month.wrong':
    'El plan mensual solo puede crearse para un máximo de 3 meses. ¡Por favor, vuelva a ingresar el período de patrullaje!',
  'plan.rest.wrong':
    'No se pueden establecer todos como días de descanso semanales, por favor reinicie.',
  'plan.bin.title': 'Vincular plan existente',
  'plan.bin.wrong':
    'Esta operación no se puede realizar porque el plan ya está vinculado a otro plan temporal.',
  'plan.bin.tooltip':
    'El plan vinculado dejará de generar informes de inspección hasta que finalice el plan temporal.',
};
