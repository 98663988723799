export default {
  'enclosure.title': 'Cerca electrónica',
  'enclosure.table.name': 'Nombre de la cerca',
  'enclosure.table.name.rule': 'Por favor, ingrese el nombre de la cerca',
  'enclosure.table.timeRange': 'Marco de tiempo',
  'enclosure.input.timeRange.add': 'Agregar tiempo',
  'enclosure.btn.add': 'Agregar cerca',
  'enclosure.warn.updateAndNotSave':
    'Si la configuración no se guarda después de modificar la cerca, ¿seguirá cerrada?',
  'enclosure.btn.clear': 'Limpiar la cerca',
  'enclosure.clear.confirm': '¿Está seguro de que desea limpiar los datos de la cerca?',
  'enclosure.btn.del': 'Eliminar cerca',
  'enclosure.del.confirm': '¿Está seguro de que desea eliminar los datos de la cerca?',
  'enclosure.btn.currentSite': 'Posición actual',
  'enclosure.table.btn.fence': 'cerca',
  'enclosure.warn.timeRange.coincide': 'El rango de tiempo se superpone, por favor, reinicie',
  'enclosure.warn.timeRange.empty': 'Por favor, seleccione el tiempo',
  'enclosure.warn.timeRange.notCheck': 'El rango de tiempo no puede estar vacío',
};
