export default {
  'system.title': 'Configuración del Sistema',
  'system.event': 'Función de Evento',
  'system.event.des': '¿La función de evento está soportada?',
  'system.plan': 'Plan Especial',
  'system.plan.des': 'Un plan que puede ejecutarse periódicamente, como diario, semanal, mensual',
  'system.patroltime': 'Tiempo de Patrullaje',
  'system.patroltime.des':
    'Si el tiempo total de patrullaje planificado de cada ronda se muestra en el informe de patrullaje',
  'system.remaintime': 'Tiempo Restante',
  'system.remaintime.des':
    'Mostrar el tiempo de inspección del mismo punto de inspección en el período de tiempo planificado en el informe de inspección',
  'system.Sequence': 'Patrullaje en Secuencia',
  'system.Sequence.des':
    'Si se debe especificar que cada punto de control en el plan debe ser inspeccionado en el orden establecido en el plan',
  'system.dst': 'Horario de Verano',
  'system.dst.des': 'Si la empresa habilita el modo de horario de verano',
  'system.dst.preview':
    'Período de horario de verano: {beginTime} ~ {endTime}, duración: {spanHour} horas',
  'system.dst.beginMonth': 'Mes de inicio',
  'system.dst.endMonth': 'Mes de fin',
  'system.dst.spanHour': 'Duración (horas)',
  'system.dst.weekVal': 'semana',
  'system.dst.weekIndex': 'El No.{indexVal}',
  'system.dst.dataNullErrorMsg': 'El horario de verano no está configurado. Cámbielo',
  'system.dst.beginMonthGeEndError': 'El mes de inicio debe ser menor que el mes de fin',
  'system.dst.weekIndex.tooltip':
    'Si no hay un número de semanas especificado en el mes, el programa se calculará según la semana especificada de la última semana.',
  'system.timeformate': 'Formato de Fecha',
  'system.timeformate.des': 'Formato de fecha mostrado por el sistema',
  'system.database.period': 'Ciclo de respaldo de la base de datos',
  'system.database.period.des': '¿Con qué frecuencia se respalda la base de datos?, unidad: día',
  'system.database.time': 'Hora de respaldo de la base de datos',
  'system.database.time.des':
    'Por favor, intente seleccionar la hora de menor actividad para la carga de datos',
  'system.database.path': 'Ruta de respaldo de la base de datos',
  'system.database.path.des':
    'Por favor, ingrese la ruta de respaldo de la base de datos, por ejemplo: d:\backup. Si la ruta está vacía, la base de datos no se respaldará',
  'system.save': 'Guardar',
  'system.enable': 'Habilitar',
  'system.disable': 'Deshabilitar',
  'system.notification.prompt': 'Aviso',
  'system.notification.success': 'Guardado exitosamente',
  'system.deptBaseDataCalMode.title': 'Modo de cálculo de datos básicos de la región',
  'system.deptBaseDataCalMode.desc':
    'Esta sección describe cómo calcular el número de tipos básicos en cada página de gestión de datos básicos y nodos del árbol de áreas',
  'system.deptBaseDataCalMode.options.currentDept': 'Solo se calcula la región actual',
  'system.deptBaseDataCalMode.options.childrenDept':
    'Calcular la región actual y la propiedad subordinada',
  'system.lowAutoSendInterval.title': 'Intervalo de alarma de batería baja (unidad: minutos)',
  'system.lowAutoSendInterval.desc':
    'Establezca el intervalo de tiempo para enviar datos y recordar a los usuarios cuando el dispositivo tenga una alarma de batería baja',
  'system.realTimeMonitorModule.title': 'Modo de monitoreo en tiempo real',
  'system.realTimeMonitorModule.desc':
    'Configurar el monitoreo en tiempo real del uso de mapas o diagramas también afecta si la cerca electrónica es efectiva',
  'system.realTimeMonitorModule.options.map': 'Mapa en línea',
  'system.realTimeMonitorModule.options.imgFile': 'Diagrama esquemático',
  'system.compantTimezoneUpt.syncMsg':
    'Si la zona horaria predeterminada de la empresa ha cambiado, determine si sincronizar la zona horaria regional que no ha cambiado',
  'system.timezone.des':
    'La zona horaria predeterminada actual de la empresa se utiliza principalmente para la configuración de la zona horaria regional y la calibración del tiempo del dispositivo',
  'system.deptTimezone.title': 'Configuración de la zona horaria regional',
  'system.deptTimezone.des':
    'Si se deben ajustar las zonas horarias por región. Después de habilitar esta función, el dispositivo puede gestionar la zona horaria por sí mismo. Después de deshabilitar esta función, la zona horaria se restaura a la zona horaria de la empresa.',
  'system.back.title': 'Respaldo de la base de datos',
  'system.back.desc':
    'Muchos datos del sistema o restaurar los datos del sistema a un punto en el tiempo',
  'system.back.lastBackTime': 'Última hora de respaldo:',
  'system.back.managementBack': 'Respaldo administrativo',
  'system.deptEmail': 'Servicio de correo regional',
  'system.deptEmail.des':
    'Después de la activación, los datos relacionados con la inspección se enviarán al correo electrónico configurado para la región',
  'system.deptEmail.enableOmit': 'Enviar los datos de omisión',
  'system.deptEmail.enableAlarm': 'Enviar alarmas no planificadas',
  'system.deptEmail.enableReport': 'Enviar el informe diario de inspección',
  'system.deptEmail.emailServerHost': 'Dirección del servidor de correo',
  'system.deptEmail.emailServerAccount': 'Cuenta del servicio de correo',
  'system.deptEmail.emailServerPwd': 'Contraseña del servicio de correo',
  'system.enclosureModule.title': 'Función de cerca electrónica',
  'system.enclosureModule.des':
    'Si se debe habilitar la función de cerca electrónica. Si se selecciona el modo de monitoreo en tiempo real según el diagrama esquemático, incluso si está habilitada, la función de cerca electrónica se bloqueará.',
};
