export default {
  'comm.1': 'Función no soportada',
  'comm.4096': 'Lector desconocido',
  'comm.4097': 'Dispositivo no encontrado',
  'comm.4098': 'Error al escribir',
  'comm.4099': 'Error al leer el dispositivo',
  'comm.4100': 'Error al abrir el dispositivo',
  'comm.4101': 'Error al conectar',
  'comm.4102': 'Tipo de dispositivo incorrecto',
  'comm.4104': 'El nombre del archivo está en blanco',
  'comm.4105': 'Error al crear el archivo',
  'comm.4150': 'La base de datos está llena, por favor elimine la huella antes de la recolección',
  'comm.4500': 'Por favor configure la voz primero y genere el archivo de la biblioteca de voz',
};
